<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :submit_preprocessing="submit_preprocessing"
            :slot_table_list="['operation']"
            ref="list"
        >
            <template slot="right_btn">
                <export-table
                    v-if="this.$store.state.page_auth.export"
                    url="/manage/crm.cost/exportCostAdd"
                ></export-table>
            </template>
            <template slot="operation" slot-scope="data">
                <span>
                    <a @click="details(data.record)">查看</a>
                </span>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { costAddList } from "@/api/cost";
import { getType } from '@/api/approval'
let columns = [
    {
        title: "发放时间",
        dataIndex: "create_time",
    },
    {
        title: "申请人",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "path_name",
    },
    {
        title: "发放客户ID",
        dataIndex: "customer_id",
    },
    {
        title: "客户名称",
        dataIndex: "customer_name",
    },
    // {
    //     title: "投放位置",
    //     dataIndex: "launch_position",
    // },
    {
        title: "附加项说明",
        dataIndex: "explain",
    },
    {
        title: "结算状态",
        dataIndex: "status_name",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: costAddList,
            submit_preprocessing: {
                array_to_string: ['department_id']
            },
            columns,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "customer",
                        title: "发放客户",
                        placeholder: '输入客户名称、ID、地址',
                        options: {

                        }
                    },
                    {
                        type: "text",
                        name: "username",
                        title: "申请人",
                        placeholder: '输入工号、姓名、手机号',
                        options: {

                        }
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },

        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
        });
        getType({
            data: {
                type: 'investment'
            }
        }).then(res => {
            let type_list = this.$method.to_form_list(res.data.list, 'id', 'name')
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type_id', 'list', type_list)
        })
    },
    methods: {
        details(data) {
            this.$router.push('attach_detail?id=' + data.id)
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>